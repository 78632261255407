import React from 'react';
import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { Container, Row, Col, Card } from 'react-bootstrap';

import * as Globals from '../../globals';
import Layout from '../components/layout/layout';
import Seo from '../components/seo/seo';
import Styles from './prototyping.styles';
import ContentCol from '../components/contentCol/contentCol';

const PrototypingPage = ({ data }) => {
  let backgroundFluidImageStack = [
    data.background.childImageSharp.fluid,
    Globals.backgroundOverlay,
  ].reverse();

  return (
    <Layout>
      <Seo title="Prototyping" />
      <Styles>
        <BackgroundImage Tag="header" fluid={backgroundFluidImageStack}>
          <Container>
            <Row>
              <Col
                xs={{ span: 10, offset: 1 }}
                md={{ span: 8, offset: 0 }}
                xl={{ span: 6, offset: 0 }}
              >
                <h1>Idea to interactive prototype in just 4 weeks</h1>
              </Col>
            </Row>
          </Container>
        </BackgroundImage>

        <Container className="main">
          <section className="my-5">
            <Row className="justify-content-center text-center">
              <ContentCol>
                <p className="lead mb-3">
                  Bring your idea to life&mdash;know the product, user experience, and costs inside &amp; out before you build.
                </p>
                <a href="https://calendly.com/generativ/prototyping/?utm_source=generativ_website&utm_medium=prototyping&utm_campaign=general_inquiry" target="_blank" rel="noreferrer" className="btn btn-info">
                  Let's Chat
                </a>
              </ContentCol>
            </Row>
          </section>

          <hr />

          <section className="mt-5 mb-4 text-center">
            <h2 className="mb-4">Our Prototyping Process</h2>

            <Row className="justify-content-center">
              <Col xs={10} md={5} className={`mb-4`}>
                <Card className={`p-3 d-flex flex-column`}>
                      <span className={`d-inline-block`}>
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search"
                           width="48" height="48" viewBox="0 0 24 24" strokeWidth="1" stroke="#000000" fill="none"
                           strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <circle cx="10" cy="10" r="7"/>
                        <line x1="21" y1="21" x2="15" y2="15"/>
                      </svg>
                    </span>
                  <h3 className={`mt-2 mb-3`}>Discovery</h3>
                  <p>We kick off each project by getting to know you and your business goals. We'll work with you to define the initial project scope and will begin user research.</p>
                </Card>
              </Col>

              <Col xs={10} md={5} className={`mb-4`}>
                <Card className={`p-3 d-flex flex-column`}>
                      <span className={`d-inline-block`}>
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-pencil" width="48"
                           height="48" viewBox="0 0 24 24" strokeWidth="1" stroke="#000000" fill="none"
                           strokeLinecap="round" strokeLinejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4"/>
  <line x1="13.5" y1="6.5" x2="17.5" y2="10.5"/>
</svg>
                    </span>
                  <h3 className={`mt-2 mb-3`}>Design</h3>
                  <p>We take the user and project background gathered in the Discovery stage and get to work building your prototype.</p>
                </Card>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col xs={10} md={5} className={`mb-4`}>
                <Card className={`p-3 d-flex flex-column`}>
                      <span className={`d-inline-block`}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-messages"
                        width="48"
                        height="48"
                        viewBox="0 0 24 24"
                        strokeWidth="1"
                        stroke="#000000"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <path d="M21 14l-3 -3h-7a1 1 0 0 1 -1 -1v-6a1 1 0 0 1 1 -1h9a1 1 0 0 1 1 1v10" />
                    <path d="M14 15v2a1 1 0 0 1 -1 1h-7l-3 3v-10a1 1 0 0 1 1 -1h2" />
                  </svg>
                    </span>
                  <h3 className={`mt-2 mb-3`}>User Testing</h3>
                  <p>Once an interactive prototype is complete, we test it on users in your target audience to gain feedback for improvements.</p>
                </Card>
              </Col>

              <Col xs={10} md={5} className={`mb-4`}>
                <Card className={`p-3 d-flex flex-column`}>
                      <span className={`d-inline-block`}>
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-wand" width="48"
                           height="48" viewBox="0 0 24 24" strokeWidth="1" stroke="#000000" fill="none"
                           strokeLinecap="round" strokeLinejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <polyline points="6 21 21 6 18 3 3 18 6 21"/>
  <line x1="15" y1="6" x2="18" y2="9"/>
  <path d="M9 3a2 2 0 0 0 2 2a2 2 0 0 0 -2 2a2 2 0 0 0 -2 -2a2 2 0 0 0 2 -2"/>
  <path d="M19 13a2 2 0 0 0 2 2a2 2 0 0 0 -2 2a2 2 0 0 0 -2 -2a2 2 0 0 0 2 -2"/>
</svg>
                    </span>
                  <h3 className={`mt-2 mb-3`}>Finishing Touches</h3>
                  <p>Using the user feedback, we iterate on the prototype to give you a polished version that works well for your target audience.</p>
                </Card>
              </Col>
            </Row>
          </section>

          <hr />

          <section className="my-5 text-center">
            <Row className={`justify-content-center`}>
              <Col xs={10} md={4} className={`mb-4`}>
                <span className={`d-inline-block mb-2`}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-bolt" width="48"
                       height="48" viewBox="0 0 24 24" strokeWidth="1" stroke="#6a5acd" fill="none"
                       strokeLinecap="round" strokeLinejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <polyline points="13 3 13 10 19 10 11 21 11 14 5 14 13 3"/>
</svg>
                </span>
                <h3>Go To Market Faster</h3>
              </Col>

              <Col xs={10} md={4} className={`mb-4`}>
                    <span className={`d-inline-block mb-2`}>
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trending-up"
                           width="48" height="48" viewBox="0 0 24 24" strokeWidth="1" stroke="#6a5acd" fill="none"
                           strokeLinecap="round" strokeLinejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <polyline points="3 17 9 11 13 15 21 7"/>
  <polyline points="14 7 21 7 21 14"/>
</svg>
                    </span>
                <h3>Show Investors Progress</h3>
              </Col>

              <Col xs={10} md={4} className={`mb-4`}>
                <span className={`d-inline-block mb-2`}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-discount" width="48"
                       height="48" viewBox="0 0 24 24" strokeWidth="1" stroke="#6a5acd" fill="none"
                       strokeLinecap="round" strokeLinejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <line x1="9" y1="15" x2="15" y2="9"/>
  <circle cx="9.5" cy="9.5" r=".5" fill="currentColor"/>
  <circle cx="14.5" cy="14.5" r=".5" fill="currentColor"/>
  <path
    d="M5 7.2a2.2 2.2 0 0 1 2.2 -2.2h1a2.2 2.2 0 0 0 1.55 -.64l.7 -.7a2.2 2.2 0 0 1 3.12 0l.7 .7a2.2 2.2 0 0 0 1.55 .64h1a2.2 2.2 0 0 1 2.2 2.2v1a2.2 2.2 0 0 0 .64 1.55l.7 .7a2.2 2.2 0 0 1 0 3.12l-.7 .7a2.2 2.2 0 0 0 -.64 1.55v1a2.2 2.2 0 0 1 -2.2 2.2h-1a2.2 2.2 0 0 0 -1.55 .64l-.7 .7a2.2 2.2 0 0 1 -3.12 0l-.7 -.7a2.2 2.2 0 0 0 -1.55 -.64h-1a2.2 2.2 0 0 1 -2.2 -2.2v-1a2.2 2.2 0 0 0 -.64 -1.55l-.7 -.7a2.2 2.2 0 0 1 0 -3.12l.7 -.7a2.2 2.2 0 0 0 .64 -1.55v-1"/>
</svg>
                </span>
                <h3>Save On Development</h3>
              </Col>
            </Row>
          </section>

          <hr />

          <section className="mt-5 mb-4">
            <h2 className="mb-3 text-center">Pricing</h2>

            <Row className="justify-content-center mb-3">
              <Col xs={10} md={4} className={`mb-4`}>
                <Card className={`p-3 d-flex flex-column`}>
                  <h3>Starter</h3>
                  <ul className="text-left pl-0">
                    <li>Interactive prototype</li>
                    <li>User testing and feedback</li>
                    <li>2 rounds of revisions</li>
                    <li>Design assets and prototype in Figma</li>
                    <li>Development estimate with feature-by-feature breakdown</li>
                  </ul>
                  <div style={{ flex: 1 }}/>
                    <h4>$5,000</h4>
                    <a href="https://calendly.com/generativ/prototyping/?utm_source=generativ_website&utm_medium=prototyping&utm_campaign=starter" target="_blank" rel="noreferrer" className="link--start">Get Started
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="24" height="24" viewBox="0 0 24 24" stroke="#6A5ACD">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                      </svg>
                    </a>
                </Card>
              </Col>
              <Col xs={10} md={4} className={`mb-4`}>
                <Card className={`p-3 d-flex flex-column`}>
                  <h3>Plus</h3>
                  <ul className="text-left pl-0">
                    <li><em>All of the features of the <strong>Starter</strong> package plus:</em></li>
                    <li>Feature roadmap recommendations</li>
                    <li>Pitch deck review</li>
                    <li>Sixty minute pitch practice call with investor feedback</li>
                  </ul>
                  <div style={{ flex: 1 }}/>
                  <h4>$6,500</h4>
                  <a href="https://calendly.com/generativ/prototyping/?utm_source=generativ_website&utm_medium=prototyping&utm_campaign=plus" target="_blank" rel="noreferrer" className="link--start">Get Started
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="24" height="24" viewBox="0 0 24 24" stroke="#6A5ACD">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                    </svg>
                  </a>
                </Card>
              </Col>
              <Col xs={10} md={4} className={`mb-4`}>
                <Card className={`p-3 d-flex flex-column`}>
                  <h3>Deluxe</h3>
                  <ul className="text-left pl-0">
                    <li><em>All of the features of the <strong>Plus</strong> package plus:</em></li>
                    <li>Marketing landing page</li>
                    <li>Founder coaching: Two 60 minute advisory calls per month for three months after prototype delivery</li>
                  </ul>
                  <div style={{ flex: 1 }}/>
                  <h4>$8,500</h4>
                  <a href="https://calendly.com/generativ/prototyping/?utm_source=generativ_website&utm_medium=prototyping&utm_campaign=deluxe" target="_blank" rel="noreferrer" className="link--start">Get Started
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="24" height="24" viewBox="0 0 24 24" stroke="#6A5ACD">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                    </svg>
                  </a>
                </Card>
              </Col>
            </Row>
          </section>

          <section className="my-5">
            <Row className="justify-content-center text-center">
              <ContentCol>
                <h2>
                  Propel your business forward in the next 4 weeks!
                </h2>
              </ContentCol>
            </Row>
          </section>

          <hr className={'mt-5 mt-md-6'} />
        </Container>
      </Styles>
    </Layout>
  );
};

export const query = graphql`
  query {
    background: file(relativePath: { eq: "backgrounds/bg-prototyping.jpg" }) {
      ...imageFluidExtraLarge
    }
  }
`;

export default PrototypingPage;
