import styled from '@emotion/styled';
import rhythm from '../utils/rhythm';
import colors from '../styles/modules/colors.module.scss';
import responsive from '../utils/responsive';

const Styles = styled.main({
  '.card': {
    height: '100%',
  },

  ul: {
    listStyle: 'none',

    li: {
      marginBottom: rhythm(0.75),
      lineHeight: rhythm(1),
    },
  },

  '.link--start': {
    color: colors.info,

    svg: {
      transform: `translateY(-${rhythm(1 / 8)})`,
    },
  },

  [responsive.Mobile]: {
    section: {
      paddingBottom: 0,
    },
  },

  [responsive.Desktop]: {
    paddingBottom: `${rhythm(4)}`,
  },
});

export default Styles;
